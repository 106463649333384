@font-face {
  font-family: "Aileron";
  src: url("../assets/fonts/Aileron-Regular.woff2") format("woff2"),
    url("../assets/fonts/Aileron-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("../assets/fonts/Aileron-Bold.woff2") format("woff2"),
    url("../assets/fonts/Aileron-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Aileron";
}
.container {
  margin: 0;
  width: 100%;
  padding: 4% 15%;
  box-sizing: border-box;
  background: url("../assets/cork-board.png");
  background-size: repeat;
  min-height: 75vh;
}
@media screen and (max-width: 1500px) {
  .container {
    padding: 4% 8%;
  }
}
