.footer {
  width: 100%;
  border-top: 3px solid #93735b;
  padding: 5vh 20% 0 20%;
  background-color: #fff4ea;
  box-sizing: border-box;
  overflow: hidden;
}
.footer img {
  width: 2rem;
  display: inline;
  transform: translateY(30%);
}
.footer span {
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 1.1rem;
}
.footer div {
  width: 50%;
  display: inline-block;
  margin-bottom: 2rem;
  box-sizing: border-box;
  text-align: center;
}
@media screen and (max-width: 850px) {
  .footer {
    padding: 5% 10%;
  }
}
@media screen and (max-width: 650px) {
  .footer span {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 500px) {
  .footer div {
    width: 100%;
  }
}
