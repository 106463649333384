.contact {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.5em;
  box-shadow: 0 0 1.5em 1.5em rgba(255, 255, 255, 0.5);
}
.contactInfo,
.map {
  box-sizing: border-box;
  height: 50vh;
  float: left;
}
.contactInfo {
  width: 40%;
}
.map {
  width: 60%;
  padding: 1%;
}
.contact {
  width: 100%;
  overflow: hidden;
  min-height: 65vh;
}
iframe {
  width: 100%;
  height: 100%;
  border: 3px solid #93735b;
}
a {
  color: black;
  text-decoration: none;
}
.contactInfo .content {
  height: 25%;
  width: 100%;
}
.contactInfo div img {
  padding: 5% 0;
  height: 100%;
  box-sizing: border-box;
  display: block;
  float: left;
}
.contactInfo div div {
  height: 100%;
  box-sizing: border-box;
  padding: 10%;
  padding-top: 5%;
  float: left;
  font-weight: 700;
  font-size: 1.2rem;
}
@media screen and (max-width: 1115px) {
  .contactInfo .content {
    padding: 2%;
  }
  .contactInfo {
    margin-bottom: 30%;
  }
  .contactInfo,
  .map {
    width: 100%;
  }
  .contactInfo {
    padding: 5% 20%;
  }
}
@media screen and (max-width: 720px) {
  .map {
    display: none;
  }
  .contactInfo {
    padding: 5% 10%;
  }
  .contact {
    padding: 0;
  }
}
@media screen and (max-width: 531px) {
  .contactInfo div img {
    padding: 10% 2%;
  }
  .contactInfo div div {
    padding: 10% 0;
  }
  .contactInfo {
    padding: 0;
    margin-bottom: 40%;
  }
}
@media screen and (max-width: 350px) {
  .contactInfo div img {
    padding: 15% 2%;
  }
  .contactInfo div div {
    font-size: 0.9rem;
  }
}
