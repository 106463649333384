.about {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.5em;
  box-shadow: 0 0 1.5em 1.5em rgba(255, 255, 255, 0.5);
}
.field {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  padding: 1.5em;
}
.edit {
  width: 100%;
  height: 20vh;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: inherit;
}
.field p{
  font-size: 1.1rem;
}
.field img,
.field div {
  padding: 2%;
  box-sizing: border-box;
  width: 50%;
  float: left;
}
@media screen and (max-width: 1100px) {
  .field img {
    padding-top: 8%;
  }
  .field {
    padding: 0;
  }
}
@media screen and (max-width: 800px) {
  .field div {
    width: 100%;
  }
  .field:nth-child(2n + 1) img {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .field:nth-child(2n) img {
    display: none;
  }
}
