.form {
  margin-top: 5%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.form input {
  width: 50%;
  margin: auto;
  display: inline-block;
}
input,
button {
  height: 30px;
  text-align: center;
  font-size: 1.2rem;
  background-color: #d8cbc0;
  border: 3px solid #93735b;
  outline: none;
  border-radius: 5px;
}
