.gallery {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.5em;
  box-shadow: 0 0 1.5em 1.5em rgba(255, 255, 255, 0.5);
  padding: 1%;
  display: grid;
  gap: 5rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}
.galleryImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.gallery h2 {
  float: right;
  margin-right: 100px;
}
.gallery a {
  color: black;
  text-decoration: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
}
.close {
  position: fixed;
  top: 20px;
  right: 20px;
}
.close span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  border-radius: 3px;
  transform-origin: 4px 0px;
  transform: rotate(-45deg) translate(1px, 2px);
}
.close span:first-child {
  transform: rotate(45deg) translate(-2px, -9px);
}
.overlay .modalImage,
.overlay video {
  position: fixed;
  max-width: 80vw;
  max-height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.iframe {
  width: 80vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.iframe div {
  left: 0px;
  width: 100%;
  height: 0px;
  position: relative;
  padding-bottom: 58.75%;
  overflow: hidden;
}
.iframe iframe {
  position: absolute;
  max-height: 100%;
  height: 100%;
  max-width: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.arrowLeft,
.arrowRight {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
}
.arrowLeft {
  left: 0;
}
.arrowRight {
  right: 0;
  transform: translate(0, -50%) rotate(180deg);
}
@media screen and (max-width: 600px) {
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
}
@media screen and (max-width: 480px) {
  .gallery {
    gap: 2.5em;
  }
  .arrowLeft {
    left: -5px;
  }
  .arrowRight {
    right: -5px;
  }
}
